import _ from 'lodash';
import { UIRouter } from '@wix/tpa-router';
import type { ControllerParams } from '@wix/yoshi-flow-editor';
import {
  editorPlugin,
  verticalsPlugin,
  viewerPlugin,
} from '@wix/tpa-router/plugins';

import groupRoutes from 'Group/routes';
import groupsRoutes from 'Groups/routes';
import type { IRootStore } from 'store/types';

import type { IAppData } from '../types';
import type { IViewModel } from './types';

/**
 * Self explanatory
 * initializes router with necessary plugins
 */
export function initializeRouter(params: ControllerParams) {
  const { isViewer } = params.flowAPI.environment;
  const { verticalsBaseUrls } = params.appData as IAppData;

  const router = new UIRouter();

  if (isViewer) {
    router.plugin(viewerPlugin(params.flowAPI));
  } else {
    router.plugin(editorPlugin(params.flowAPI));
  }

  router.plugin(verticalsPlugin(), { baseUrls: verticalsBaseUrls });

  return router;
}

/**
 * Register all verticals routes
 */
export function registerVerticalsRoutes(router: UIRouter) {
  router.stateRegistry.register({
    parent: 'tpa',
    abstract: true,
    name: 'social-groups',
    url: '?social-groups-ooi-override',
  });

  router.stateRegistry.register({
    parent: 'tpa',
    name: 'pricing-plans',
    url: '?{appSectionParams}',
  });

  router.stateRegistry.register({
    parent: 'tpa',
    name: 'members',
  });
}

/**
 * Registers all internal application routes
 */
export function registerApplicationRoutes(
  router: UIRouter,
  vm: IViewModel,
  store: IRootStore,
  params: ControllerParams,
) {
  const declarationFns = _.flatten([groupRoutes, groupsRoutes]);

  for (const declarationFn of declarationFns) {
    const states = _.flatten([declarationFn(vm, store, params)]);

    for (const state of states) {
      router.stateRegistry.register(state);
    }
  }
}

/**
 * reloads the root state on user login
 */
export function reloadOnLogin(router: UIRouter, params: ControllerParams) {
  const { wixCodeApi } = params.controllerConfig;

  wixCodeApi.user.onLogin(() => router.stateService.reload('social-groups'));
}
